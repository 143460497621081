<template>
    <div class="fixed top left w-100 h-100 layer-1000">
        <div class="popup__shadow w-100 h-100" @click.stop="close"/>
        <div class="popup__content flex-column fixed background-white">
            <div class="flex-row w-100 justify-content-end padding20">
                <img src="@/assets/icons/x-green.svg" class="popup__icon cursor-pointer" alt="Close popup" @click.stop="close"/>
            </div>
            <div class="flex-row w-100 flex-grow-1">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        close(){
            this.$emit('close');
        }
    },
};
</script>

<style lang="scss" scoped>
.popup__shadow {
    background: rgba(0, 0, 0, 0.7);
}
.popup__content {
    top: 50%;
    left: 50%;
    min-width: 20%;
    max-width: 90%;
    min-height: 30%;
    max-height: 90%;
    transform: translate(-50%, -50%);
    border-radius: 19px;
    line-height: normal;
    color: #656565;
}
.popup__icon {
    width: 2rem;
    height: 2rem;
}
.popup__social {
    width: 3rem;
    height: 3rem;
}
.popup__text {
    font-size: 1.5rem;
}
.popup__title {
    font-size: 1.8rem;
}
.popup__clickable {
    transition: opacity 0.2s linear;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}
.popup__embed {
    width: 90px;
    height: 90px;
}

@media (min-width: 768px) {
    max-width: 70%;
    max-height: 80%;
}

.popup__social {
    width: 6rem;
    height: 6rem;
}
</style>