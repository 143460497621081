<template>
    <PannellumApi v-if="pannellumTour" :tour="pannellumTour" />
    <div v-else>Not found</div>
</template>

<script>
import PannellumApi from "@/components/libraries/pannellumApi";
import cloneDeep from "clone-deep";
export default {
    name: 'TourViewer',
    components: {PannellumApi},
    props: {
        tourId: String
    },
    data() {
        return {
            tour: null,
            hotspotConfig: {
                type: 'info',
                color: null,
                cssClass: null,
                zoom: null
            },
            author: '',
            authorUrl: '',
            watermark: '',
            currentSceneId: null
        }
    },
    computed: {
        scenesObject() {
            if(!this.tour?.scenes)
                return {};
        
            return this.tour.scenes.reduce((scenes, scene) => {
                const sceneId = scene.id ?? 'N-' + (Math.random() * 10000).toFixed(0);
                scenes[sceneId] = {
                    title: scene.title,
                    panorama: this.getPrivateImageURL(scene.images[0].path),
                    thumbnail: this.getPrivateImageURL(scene.images[0].path, -3, 'small'),
                    hotSpots: (scene.hotspots ?? []).map(hotspot => this.formatHotspotToPannellum(hotspot, sceneId)),
                    order: scene.order ?? -1
                }
                return scenes;
            },{});
        },
        mainSceneId() {
            const mainSceneId = this.sortedScenes.find(scene => {
                return !!scene['is_main']
            }) ?? null;
        
            return mainSceneId != null ? mainSceneId : (this.sortedScenes.length > 0 ? this.sortedScenes[0].id : null);
        },
        pannellumTour() {
            if(this.scenesObject === {} || !this.tour)
                return null;
            return {
                default: {
                    type: 'equirectangular',
                    title: this.tour?.title ?? '',
                    autoLoad: true,
                    escapeHTML: true,
                    author: this.author,
                    authorURL: this.authorUrl,
                    hfov: 130,
                    firstScene: this.mainSceneId
                },
                scenes: this.scenesObject,
                watermark: this.getPrivateImageURL(this.watermark),
                showNavbar: true,
                showTitle: true,
                url: window.location.href,
                hotspotsColor: this.tour.hotspots_color,
                hotspotsZoom: this.tour.hotspots_zoom,
            };
        },
        hotspotSceneCssClass() {
            return this.tour?.hotspots_css_class_scene ?? 'tour__hotspot--shortcut-thumbnail';
        },
        hotspotInfoCssClass() {
            return this.tour?.hotspots_css_class_info ?? 'tour__hotspot--info-solid';
        },
        sortedScenes() {
            if(!this.tour?.scenes)
                return []
        
            const entries = Object.entries(this.tour.scenes);
            const scenes = entries.map(([id,scene]) => { return {id,...scene} });
            return scenes.sort((sceneA, sceneB) => sceneA.order != null && sceneA.order !== -1 && sceneB.order != null && sceneA.order < sceneB.order ? -1 : 1);
        },
    },
    mounted() {
        this.retrieveTour();
    },
    methods:{
        async retrieveTour(){
            if(!this.tourId)
                return;
            const { data } = await this.axios.get(`/api/tours/${this.tourId}`);
            if(data) {
                this.tour = data.tour;
                this.author = data.author;
                this.authorUrl = data.authorUrl;
                this.watermark = data.watermark;
            }
            this.currentSceneId = this.mainSceneId
        },
        getPrivateImageURL(image, gender = -1, thumb = 'original', defaultImage = true) {
            let imageUrl;
            
            if (image && typeof image === 'string') {
                if (image.startsWith('/storage') ||
                    image.startsWith('/images') ||
                    image.startsWith('/image') ||
                    image.startsWith('/file') ||
                    image.startsWith('http') ||
                    image.startsWith('data:')) {
                    imageUrl = image;
                }
                if (thumb === 'big') {
                    imageUrl = "/thumb/private/1920/1080/0/" + image;
                } else if (thumb === 'medium') {
                    imageUrl = "/thumb/private/800/600/0/" + image;
                } else if (thumb === 'small') {
                    imageUrl = "/thumb/private/200/200/0/" + image;
                } else {
                    // Original size
                    imageUrl = "/file/" + image;
                }
            }
        
            if(imageUrl || !defaultImage)
                return this.axios.defaults.baseURL + (imageUrl ?? '');
            
            let noImage;
            
            if (gender === -2) {
                // For properties
                noImage = 'no-photo3.svg';
            } else if (gender !== -1) {
                noImage = gender === 2 ? 'female.svg' : 'male.svg';
            } else {
                noImage = 'no-photo2.png';
            }
        
            return this.axios.defaults.baseURL + (noImage ? "/images/icons/" + noImage : imageUrl);
        },
        formatHotspotToPannellum(hotspot){
            let formattedHotspot = cloneDeep(hotspot);
            let thumbnail = '';
        
            if(hotspot.target_scene_id) {
                const targetScene = this.tour.scenes.find(scene => '' + scene.id === '' + hotspot.target_scene_id);
                thumbnail = this.getPrivateImageURL(targetScene.images[0].path, -3, 'small');
            
                formattedHotspot.sceneId = hotspot.target_scene_id;
                delete formattedHotspot.target_scene_id;
            }
        
            formattedHotspot.cssClass = hotspot.type === 'info' ? this.hotspotInfoCssClass : this.hotspotSceneCssClass;
        
            let container = document.createElement('div');
            container.classList.add('relative', 'w-100', 'h-100')
        
            const toggleContainer = () => {
                if(container.classList.contains('is-open'))
                    container.classList.remove('is-open');
                else
                    container.classList.add('is-open');
            }
        
            formattedHotspot.createTooltipFunc = (object) => {
                if(thumbnail)
                    object.style.setProperty('--thumbnail-url', 'url(' + thumbnail + ')');
            
                if(hotspot.type === 'info' && hotspot.text){
                    let commentContainer = document.createElement('div');
                    commentContainer.classList.add('hotspot__comment');
                
                    let closeButton = document.createElement('div');
                    closeButton.classList.add('hotspot__close');
                
                    let infoText = document.createElement('div');
                    infoText.innerHTML = hotspot.text;
                    infoText.classList.add('hotspot__text');
                    infoText.dataset.ignoreHotspotClick = '1';
                
                    commentContainer.append(closeButton, infoText)
                
                    container.append(commentContainer);
                }
            
                object.append(container);
            
                return object;
            };
        
            formattedHotspot.clickHandlerFunc = event => {
                if(event.target.dataset.ignoreHotspotClick)
                    return;
            
                toggleContainer();
            };
        
            return cloneDeep(formattedHotspot);
        },
    }
}
</script>

<style scoped>
</style>
