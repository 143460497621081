<template>
    <div class="h-100 w-100 relative">
        <div ref="pannellumContainer" @contextmenu.prevent.stop="contextmenu" @drop="dropWithCoords"
             @dragenter.prevent="doNothing" @dragover.prevent="doNothing" :class="{ 'hide-info' : !this.tour.showTitle }"
             :style="{ '--hotspot-color': tour.hotspotsColor || '#1e7c6b', '--hotspot-scale': tour.hotspotsZoom / 100 || 1}"
        >
            <a v-if="!hideLinks && tour.watermark" class="tour__watermark" :href="authorURL" target="_blank">
                <img class="w-100 h-100" :src="tour.watermark" :alt="author"/>
            </a>
            <div class="tour__scenes absolute bottom left right flex-column layer-10"
                 v-if="tour.showNavbar">
                <div class="flex-row w-100 justify-content-between menu__toolbar" :class="{ 'is-closed': !isMenuOpen}">
                    <div class="paddingRight15 paddingLeft15 paddingTop15" :class="{ paddingBottom15: isMenuOpen}">
                        <div @click="isMenuOpen = !isMenuOpen" class="cursor-pointer menu__tool flex-row align-items-center">
                            <div class="icon marginRight5" :style="'--icon-src: url(' + require('@/assets/icons/image-slider.svg') + ')'"/>
                            <div class="triangle" :class="isMenuOpen ? 'down' : 'up'"/>
                        </div>
                    </div>
                    <div class="paddingRight15 paddingLeft15 paddingTop15 flex-row align-items-center" :class="{ paddingBottom15: isMenuOpen}">
                        <div v-if="!hideLinks" @click="toggleSharePopup" class="menu__tool marginRight15 cursor-pointer">
                            <div class="icon" :style="'--icon-src: url(' + require('@/assets/icons/share.svg') + ')'"/>
                        </div>
                        <div @click="toggleFullScreen" class="menu__tool cursor-pointer">
                            <div class="icon" :style="'--icon-src: url(' + require('@/assets/icons/full-screen.svg') + ')'"/>
                        </div>
                    </div>
                </div>
                <div v-if="isMenuOpen" class="flex-row w-100 justify-content-center align-items-center paddingLeft15 paddingRight15 background-black-40">
                    <button @click="loadPreviousScene" class="menu__button marginRight15" :disabled="!hasPreviousScene">
                        <img src="@/assets/icons/arrow-left.svg" class="icon" alt="Previous scene">
                    </button>
                    <div class="flex-row gap is-shortcut overflow-x-scroll hide-scrollbar scroll-smooth align-items-center" >
                        <img v-for="scene in sortedScenes" :key="scene.id" @click="currentSceneId = scene.id" :src="scene.thumbnail" :alt="'Imagen de ' + scene.title"
                             class="tour__menuItem is-shortcut" :class="{ 'is-selected': currentSceneId === scene.id }" draggable="false"
                             :ref="getMenuSceneRef(scene.id)"
                        />
                    </div>
                    <button @click="loadNextScene" class="menu__button marginLeft15" :disabled="!hasNextScene">
                        <img src="@/assets/icons/arrow-left.svg" class="flip-horizontal icon" alt="Next scene">
                    </button>
                </div>
                <div class="flex-row w-100 justify-content-end background-black-40 menu__footer paddingBottom3 paddingTop10 paddingRight10">
                    <p v-if="hideLinks">&copy; {{ (new Date()).getUTCFullYear() }}</p>
                    <template v-else>
                        <a :href="authorURL" target="_blank" class="paddingRight10">&copy; {{author}}</a>
                        {{ " | " }}
                        <a :href="getDefaultAuthorUrl()" target="_blank" class="paddingLeft10"> {{ getDefaultAuthor() }}</a>
                    </template>
                </div>
            </div>
        </div>
        <sharePopup v-if="isSharePopupOpen" @close="toggleSharePopup" name="Tour Virtual" :url="tour.url"/>
    </div>
</template>

<script>
import 'pannellum';
import 'pannellum/build/pannellum.css';
import cloneDeep from "clone-deep";
import SharePopup from "@/components/popups/sharePopup";
import Events from "@/enums/libreries/pannellum/Events";

export default {
    name: "pannellumApi",
    components: {SharePopup},
    props: {
        tour: Object,
        value: null
    },
    data() {
        return {
            viewer: null,
            isMenuOpen: true,
            isSharePopupOpen: false,
            currentSceneId: null,
            hideLinks: this.$route.query.hideLinks
        }
    },
    computed: {
        sortedScenes() {
            if(!this.tour?.scenes)
                return []
            
            const entries = Object.entries(this.tour.scenes);
            const scenes = entries.map(([id,scene]) => { return {id,...scene} });
            return scenes.sort((sceneA, sceneB) => sceneA.order != null && sceneA.order !== -1 && sceneB.order != null && sceneA.order < sceneB.order ? -1 : 1);
        },
        author(){
            return this.tour?.default?.author ?? this.getDefaultAuthor();
        },
        authorURL(){
            return this.tour?.default?.authorURL ?? this.getDefaultAuthorUrl();
        },
        hasPreviousScene(){
            return this.getCurrentSceneIndex() > 0;
        },
        hasNextScene(){
            return (this.getCurrentSceneIndex() + 1) < this.sortedScenes.length;
        }
    },
    watch: {
        tour: {
            deep: true,
            handler(newTour, oldTour) {
                this.renderDifferences(newTour, oldTour);
            }
        },
        currentSceneId(sceneId){
            this.loadScene(sceneId);
        }
    },
    mounted() {
        this.loadViewer();
    },
    methods: {
        getDefaultAuthor(){
            return "Emblematic";
        },
        getDefaultAuthorUrl(){
            return "https://www.emblematic.es";
        },
        getMenuSceneRef(id){
            return 'menu__scene__' + id;
        },
        loadViewer(){
            if(this.viewer)
                this.viewer.destroy();
            
            let tourConfig = cloneDeep(this.tour);
            tourConfig.showControls = false;
            
            this.viewer = window.pannellum.viewer(this.$refs.pannellumContainer, tourConfig);
            this.setEventEmitters();
            const self = this;
            this.viewer.on(Events.SCENE_CHANGE, () => {
                self.currentSceneId = '' + self.viewer.getScene()
            });
            this.currentSceneId = '' + self.viewer.getScene();
        },
        renderDifferences(newTour, oldTour) {
            if(!this.viewer){
                this.loadViewer();
                return;
            }
            
            const oldScenes = Object.entries(oldTour?.scenes ?? {});
            const oldScenesIds = oldScenes.map(([id]) => id);
            const newScenes = Object.entries(newTour?.scenes ?? {});
            
            const commonScenesIds = oldScenesIds.filter(
                (oldId) => newScenes.some(([newId]) => oldId === newId));
            
            oldScenesIds.filter(id => !commonScenesIds.includes(id)).forEach(this.viewer.removeScene);
            newScenes.filter(([id]) => !commonScenesIds.includes(id)).forEach(([id,config]) => this.viewer.addScene(id,config));
            
            commonScenesIds.forEach(sceneId => {
                const [, oldScene] = oldScenes.find(([id]) => id === sceneId) ?? [];
                const [, newScene] = newScenes.find(([id]) => id === sceneId) ?? [];
                const oldHotSpots = oldScene.hotSpots ?? [];
                const newHotSpots = newScene.hotSpots ?? [];
                
                oldHotSpots.filter(hotSpot => !!hotSpot.id)
                    .forEach(hotSpot => this.viewer.removeHotSpot(hotSpot.id, sceneId));
                newHotSpots.filter(hotSpot => !!hotSpot.id).forEach(hotSpot => {
                    this.viewer.addHotSpot(hotSpot, sceneId)
                });
            });
        },
        doNothing(){
            return false;
        },
        dropWithCoords(event){
            if(!this.viewer)
                return;
            
            this.$emit('drop', this.viewer.mouseEventToCoords(event), this.viewer.getScene(), event.dataTransfer.getData('text/plain'))
        },
        contextmenu(event){
            if(!this.viewer)
                return;
            
            this.$emit('contextmenu', this.viewer.mouseEventToCoords(event), event)
        },
        setEventEmitters(viewer = this.viewer){
            if(!viewer)
                return;
            
            Object.values(Events).forEach(
                eventName => viewer.on(eventName, (...args) => this.$emit(eventName, ...args))
            )
        },
        getCurrentSceneIndex(){
            if(!this.sortedScenes || this.currentSceneId == null)
                return;
            return this.sortedScenes.findIndex(({ id }) => `${id}` === `${this.currentSceneId}`);
        },
        loadNextScene(){
            const index = this.getCurrentSceneIndex();
            this.currentSceneId = this.sortedScenes[index + 1].id ?? this.currentSceneId;
        },
        loadPreviousScene(){
            const index = this.getCurrentSceneIndex();
            this.currentSceneId = this.sortedScenes[index - 1].id ?? this.currentSceneId;
        },
        loadScene(id){
            if(!this.viewer || id == null)
                return;
            
            this.viewer.loadScene(id);
            
            const menuItemRef = this.getMenuSceneRef(id);
            if(this.$refs[menuItemRef])
                this.$refs[menuItemRef][0].scrollIntoView(false);
        },
        toggleSharePopup(){
            this.isSharePopupOpen = !this.isSharePopupOpen;
        },
        toggleFullScreen(){
            if(!this.viewer)
                return;
            this.viewer.toggleFullscreen();
        }
    }
}


</script>
<style lang="scss">
.hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.scroll-smooth {
    scroll-behavior: smooth;
}

.flip-horizontal {
    transform: scaleX(-1);
}

.pnlm-about-msg, .pnlm-author-box{
    display:none !important;
}

.pnlm-render-container {
    position: relative;
}

.hide-info {
    .pnlm-panorama-info {
        display: none !important;
    }
}
.pnlm-panorama-info {
    top: 0;
    left: 0;
    bottom: unset;
    min-width: 25%;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.31);
    
    padding: 0.5rem 1.5rem;
    box-sizing: border-box;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: lighter;
    
    &::after{
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 200%;
        width: 6rem;
        background: rgba(0, 0, 0, 0.31);
        transform: translate(100%, -51%);
        border-radius: 0 0 60% 0;
    }
    
    .pnlm-title-box {
        display: block;
        margin: 0;
        padding: 0;
        text-transform: lowercase;
        
        &::first-letter {
            text-transform: capitalize;
        }
    }
}

.tour__watermark {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    max-width: 15%;
    max-height: 20%;
    z-index: 100;
}

.tour__scenes {
    * {
        box-sizing: border-box;
    }
}

.tour__menuItem {
    width: 88px;
    height: 49px;
    box-sizing: border-box;
    
    &.is-shortcut {
        cursor: pointer;
    }
    &.is-selected {
        border: 2px solid #5bbfae;
    }
}

.hotspot__comment {
    display: none;
    position: absolute;
    bottom: 130%;
    left: 50%;
    width: 400%;
    transform: translateX(-50%);
    
    .hotspot__close {
        display: inline;
        position: absolute;
        right: 0;
        transform: translateY(-140%);
        height: 1rem;
        width: 1rem;
        padding: 0.5rem;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.7) url('~@/assets/icons/x-black.svg') no-repeat 50% 50%;
        background-size: 50%;
    }
    
    .hotspot__text {
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 5px;
        padding: 1rem 1.5rem;
    }
}

.background-black-40{
    background-color: rgba(20,20,20,0.4);
}

.triangle {
    width: 0;
    height: 0;
    
    border: 6px solid transparent;
    --border-color: #fff;
    
    &.up{
        border-bottom: 6px solid var(--border-color);
        transform: translateY(-25%);
    }
    
    &.down{
        border-top: 6px solid var(--border-color);
        transform: translateY(25%);
    }
}

.menu__toolbar {
    background: linear-gradient(0deg, rgba(20,20,20,0.4) 50%, rgba(20,20,20,0) 50%);
    
    > *:first-child {
        border-radius: 0 7px 0 0;
        background: linear-gradient(0deg, rgba(20,20,20,0) 50%, rgba(20,20,20,0.4) 50%);
    }
    > *:last-child {
        border-radius: 7px 0 0 0;
        background: linear-gradient(0deg, rgba(20,20,20,0) 50%, rgba(20,20,20,0.4) 50%);
    }
    
    &.is-closed {
        background: none;
        > * {
            background: rgba(20,20,20,0.4);
        }
    }
}

.menu__tool {
    .icon {
        width: 44px;
        height: 21px;
        background-color: #fff;
        -webkit-mask: var(--icon-src) no-repeat 50% 50%;
        mask: var(--icon-src) no-repeat 50% 50%;
        -webkit-mask-size: contain;
        mask-size: contain;
    }
    
    &:hover{
        .icon {
            background-color: #5bbfae;
        }
        .triangle{
            --border-color: #5bbfae;
        }
    }
}

.menu__footer {
    font-family: 'Lato', sans-serif;
    font-size: 1.1rem;
    color: #fff;
    > a {
        font-family: 'Lato', sans-serif;
        color: #fff;
        text-decoration: none;
    }
}

.menu__button {
    .icon{
        padding: 6px;
        background-color: #1e7c6b;
        border-radius: 50%;
        opacity: 0.4;
        transition: opacity 0.2s linear;
        
        &:hover{
            opacity: 0.8;
        }
    }
    
    &:disabled .icon{
        opacity: 0.2;
    }
}

/** Hotspots animations */

.tour__hotspot--shortcut-pulsating {
    $scale-duration: 5s;
    
    width: calc(5rem * var(--hotspot-scale) / 5);
    height: calc(5rem * var(--hotspot-scale) / 5);
    border-radius: 50%;
    background: var(--hotspot-color);
    
    &:before,
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: inherit;
        opacity: .3;
        position: absolute;
        top: 0;
        left: 0;
        transition: box-shadow .3s;
        animation: pulse-aninmation $scale-duration ease-in-out infinite both;
    }
    
    &:after {
        animation-delay: $scale-duration / 2;
    }
    
    &:hover {
        &:before,
        &:after {
            box-shadow: 0 0 .5px .5px var(--hotspot-color);
        }
    }
}
@keyframes pulse-aninmation {
    0% { transform: scale(1); }
    50% { transform: scale(5); }
    100% { transform: scale(1); }
}

.tour__hotspot--shortcut-block {
    --hotspot-size: calc(5rem * var(--hotspot-scale));
    width: var(--hotspot-size);
    height: var(--hotspot-size);
    
    &::after, &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: pulse 0.75s ease-in infinite alternate;
        background-image: repeating-linear-gradient(45deg, var(--hotspot-color) 25%, transparent 25%, transparent 75%, var(--hotspot-color) 75%, var(--hotspot-color)), repeating-linear-gradient(45deg, var(--hotspot-color) 25%, transparent 25%, transparent 75%, var(--hotspot-color) 75%, var(--hotspot-color));
        background-size: var(--hotspot-size) var(--hotspot-size);
        background-position: 0 0, calc(var(--hotspot-size) / 2) calc(var(--hotspot-size) / 2);
    }
    
    &::before {
        animation-delay: 0.75s;
        transform: rotateY(180deg);
    }
}
@keyframes pulse {
    0%   { opacity: 1 }
    100% { opacity: 0 }
}

.tour__hotspot--shortcut-ripple {
    --hotspot-size: calc(var(--hotspot-scale) * 5rem);
    width: var(--hotspot-size);
    height: var(--hotspot-size);
    
    &::after, &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 4px solid var(--hotspot-color);
        opacity: 1;
        border-radius: 50%;
        animation: ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        box-sizing: border-box;
    }
    
    &::before {
        animation-delay: -0.5s;
    }
}
@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0);
    }
    
    to {
        opacity: 0;
        transform: scale(1);
    }
}

.tour__hotspot--shortcut-box {
    --hotspot-size: calc(var(--hotspot-scale) * 5rem);
    width: var(--hotspot-size);
    height: var(--hotspot-size);
    
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border:  solid var(--hotspot-color);
        animation: borderAnim 1.25s  alternate infinite ease-in-out, loaderAnim 1.25s infinite ease-in-out;
        box-sizing: border-box;
    }
}
@keyframes borderAnim {
    0% {
        border-width: calc(var(--hotspot-size) * 0.2 / 2);
    }
    100% {
        border-width: calc(var(--hotspot-size) * 0.75 / 2);
    }
}
@keyframes loaderAnim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

.tour__hotspot--shortcut-thumbnail {
    --hotspot-size: calc(var(--hotspot-scale) * 5rem);
    width: var(--hotspot-size);
    height: var(--hotspot-size);
    
    &::before, &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        box-sizing: border-box;
    }
    
    &::before {
        filter: blur(calc(var(--hotspot-size) / 6));
        background: rgba(0,0,0,0.4);
        animation: spin 2.5s linear infinite;
    }
    
    &::after {
        top: 0;
        left: 0;
        background-image: var(--thumbnail-url);
        background-size: cover;
        background-position: 50% 50%;
        border: 3px solid #fff;
    }
}

@keyframes spin {
    0% {
        transform: translate(-5%,0);
    }
    25% {
        transform: translate(0,10%);
    }
    50% {
        transform: translate(5%,0);
    }
    75% {
        transform: translate(0,-5%);
    }
    100% {
        transform: translate(-5%,0);
    }
}

.tour__hotspot--info-solid::after {
    -webkit-mask:  url('~@/assets/icons/info-solid.svg') no-repeat 50% 50%;
    mask: url('~@/assets/icons/info-solid.svg') no-repeat 50% 50%;
}
.tour__hotspot--info-light::after {
    -webkit-mask:  url('~@/assets/icons/info-light.svg') no-repeat 50% 50%;
    mask: url('~@/assets/icons/info-light.svg') no-repeat 50% 50%;
    
}
.tour__hotspot--info-exclamation-solid::after {
    -webkit-mask:  url('~@/assets/icons/exclamation-solid.svg') no-repeat 50% 50%;
    mask: url('~@/assets/icons/exclamation-solid.svg') no-repeat 50% 50%;
}
.tour__hotspot--info-exclamation-light::after {
    -webkit-mask:  url('~@/assets/icons/exclamation-light.svg') no-repeat 50% 50%;
    mask: url('~@/assets/icons/exclamation-light.svg') no-repeat 50% 50%;
}
.tour__hotspot--info-question-solid::after {
    -webkit-mask:  url('~@/assets/icons/question-solid.svg') no-repeat 50% 50%;
    mask: url('~@/assets/icons/question-solid.svg') no-repeat 50% 50%;
}
.tour__hotspot--info-question-light::after {
    -webkit-mask:  url('~@/assets/icons/question-light.svg') no-repeat 50% 50%;
    mask: url('~@/assets/icons/question-light.svg') no-repeat 50% 50%;
}

[class ^= 'tour__hotspot--info-'], [class *= ' tour__hotspot--info-']
{
    --hotspot-size: calc(var(--hotspot-scale) * 5rem);
    width: var(--hotspot-size);
    height: var(--hotspot-size);
    
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        border-radius: 50%;
        
        background-color: var(--hotspot-color);
        -webkit-mask-size: contain;
        mask-size: contain;
    }
    
}

.pnlm-hotspot-base .is-open {
    .hotspot__comment {
        display: unset;
    }
}

</style>