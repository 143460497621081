export default Object.freeze({
    LOAD: 'load',
    SCENE_CHANGE: 'scenechange',
    FULL_SCREEN_CHANGE: 'fullscreenchange',
    ZOOM_CHANGE: 'zoomchange',
    SCENE_CHANGE_FADE_DONE: 'scenechangefadedone',
    ANIMATE_FINISH: 'animatefinished',
    ERROR: 'error',
    ERROR_CLEARED: 'errorcleared',
    MOUSE_DOWN: 'mousedown',
    MOUSE_UP: 'mouseup',
    TOUCH_START: 'touchstart',
    TOUCH_END: 'touchend'
});