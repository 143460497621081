<template>
    <div class="textInput displayBlock" :class="[classes, !noPadding ? 'paddingTextarea' : '']">
        <p>
            <span>{{ firstWord }}</span
            >{{ labelText }}
        </p>

        <textarea
            class="textareaOffer"
            v-model="inputValue"
            :class="[classes, noMargin ? 'no-margin' : '', noDefaultHeight ? 'no-height' : '']"
            :maxlength="maxLength"> </textarea>
        <p v-if="maxLength" class="w-100 align-right">{{ (inputValue && inputValue.length) || 0 }} caracteres</p>

    </div>
</template>
<script>
export default {
    props: ['firstWord', 'labelText', 'value', 'classes', 'maxLength', 'noPadding', 'noMargin', 'noDefaultHeight'],
    computed: {
        inputValue : {
          get() {
            return this.value;
          },
          set(newValue) {
            this.$emit('input', newValue);
          }
        }
    },

};
</script>

<style lang="scss" scoped>

textarea {
    width: 100%;
    padding: 14px 4px 4px 13px;
    border-radius: 3px;
    box-shadow: 0 4px 5px 0 rgba(30, 124, 107, 0.05);
    border: solid 1px #9dddd3;
    background-color: #fcfffe;
    font-size: 15px;
    
    &:not(.no-margin){
        margin: 7px 0 0;
    }
    
    &:not(.no-height){
        height: 243px;
        max-height: 243px;
    }
    
    &.pagWeb {
        height: 180px;
        width: 460px;
        padding: 6px 4px 4px 6px;
    }
    
    &.genWeb {
        width: 460px;
        height: 350px;
    }
    
    &.webElem {
        width: 215px;
        height: 225px;
        padding: 6px 4px 4px 6px;
    }
}

</style>
