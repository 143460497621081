<template>
    <popup>
        <div class="flex-column gap-2 w-100 paddingRight40 paddingLeft40 paddingBottom40">
            <div class="flex-column gap">
                <div class="flex-row popup__title">Compartir el {{ name }} en redes sociales o vía email:</div>
                <div class="flex-row gap-1 gap-md-4 flex-wrap justify-content-between">
                    <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + url" target="_blank"><img src="~@/assets/icons/social-networks/facebook.svg" alt="Compartir en facebook" class="popup__social" /></a>
                    <a :href="'https://twitter.com/intent/tweet?text=' + url" target="_blank"><img src="~@/assets/icons/social-networks/twitter.svg" alt="Compartir en twitter" class="popup__social" /></a>
                    <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + url" target="_blank"><img src="~@/assets/icons/social-networks/linkedin.svg" alt="Compartir en linkedin" class="popup__social" /></a>
                    <a :href="'mailto:?body=' + url" target="_blank"><img src="~@/assets/icons/social-networks/mail.svg" alt="Compartir por email" class="popup__social" /></a>
                </div>
            </div>
            <div class="flex-column gap">
                <div class="flex-row popup__title">Copiar y compartir el {{ name }} a través de este link:</div>
                <div class="flex-row">
                    <copiableText :value="url" />
                </div>
            </div>
            <div class="flex-row justify-content-between gap-1 gap-md-4">
                <div class="flex-column gap">
                    <div class="flex-row popup__title">Descargar el código QR</div>
                    <div class="flex-row justify-content-center" @click="downloadQr">
                        <QrcodeVue :value="url" :size="90" level="L" ref="tourQr" class="cursor-pointer"/>
                    </div>
                </div>
                <div class="flex-column gap">
                    <div class="flex-row popup__title">Incrustar el {{ name }} en una web</div>
                    <div class="flex-row justify-content-center flex-grow-1">
                        <itemTextarea v-if="isIframeShown"
                                      class="h-100 w-100" classes="h-100 w-100"
                                      :value="iframeHtml" :noMargin="true" :noPadding="true" :noDefaultHeight="true"/>
                        <img v-else src="~@/assets/icons/social-networks/embed.svg" alt="Embed tour in a website" class="popup__clickable popup__embed cursor-pointer"
                             @click="isIframeShown = true">
                    </div>
                </div>
            </div>
        </div>
    </popup>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import Popup from "@/components/popups/basePopup";
import CopiableText from "@/components/form/copiableText";
import ItemTextarea from "@/components/form/itemTextarea";

export default {
    name: 'sharePopup',
    components: {ItemTextarea, CopiableText, Popup, QrcodeVue},
    props: {
        name: String,
        url: String
    },
    data() {
        return {
            isIframeShown: false
        }
    },
    computed: {
        iframeHtml(){
            return "<iframe style='border:0px #ffffff none;width: 100%;height: 100%;' src='" + this.url + "' scrolling='no' frameborder='1' allowfullscreen=''></iframe>";
        }
    },
    methods: {
        downloadQr(){
            const link = document.createElement('a');
            link.download = 'tour-qr.png';
            link.href = this.$refs.tourQr.$el.getElementsByTagName('canvas')[0]?.toDataURL() ?? ''
            link.click();
        }
    }
}
</script>