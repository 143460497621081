import { createApp } from 'vue'
import App from './App.vue'
import TourViewer from "@/components/TourViewer";
import {createRouter, createWebHistory} from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import "@/assets/styles/reset.css";
import "@/assets/styles/normalize.css";
import "@/assets/styles/_layout.scss";
import "@/assets/styles/_spacing.scss";
import "@/assets/styles/_thatCSS_20180412.scss";
import "@/assets/styles/_global.scss";

const router = createRouter({
    history: createWebHistory('/'),
    routes: [{
        path: '/:tourId',
        name: 'tour-viewer',
        props: true,
        component: TourViewer
    }]
});

axios.defaults.baseURL = 'https://app.emblematic.es/';

const app = createApp(App);
app.use(router);
app.use(VueAxios, axios)
app.mount('#app');
