<template>
    <div class="copiable__container flex-row w-100">
        <input type="text" :value="value" disabled class="flex-grow-1 min-width-0 paddingTop5 paddingBottom5 paddingLeft15 paddingRight15">
        <button @click="copyValueToClipboard" class="copiable__button paddingLeft15 paddingRight15">{{ isCopied ? '¡COPIADO!' : 'COPIAR' }}</button>
    </div>
</template>

<script>
export default {
    name: "copiableText",
    props: {
        value: String
    },
    data() {
        return {
            isCopied: false,
            copiedTimeout: null
        }
    },
    methods: {
        copyValueToClipboard(){
            navigator.clipboard.writeText(this.value);
            this.isCopied = true;
            if(this.copiedTimeout)
                clearTimeout(this.copiedTimeout)
            this.copiedTimeout = setTimeout(this.toggleCopied, 2000);
        },
        toggleCopied(){
            this.isCopied = !this.isCopied;
            this.copiedTimeout = null;
        }
    }
}
</script>

<style lang="scss" scoped>
.copiable__container {
    border: 1px solid #1e7c6b;
    background-color: rgba(188, 226, 219, 0.21);
    border-top-left-radius: 1.5rem 50%;
    border-top-right-radius: 1.5rem 50%;
    border-bottom-left-radius: 1.5rem 50%;
    border-bottom-right-radius: 1.5rem 50%;
    font-size: 1.5rem;
    line-height: 1.8rem;
    opacity: 0.8;
    transition: opacity 0.2s linear;
    &:hover {
        opacity: 1;
    }
}
.copiable__button {
    color: #fff;
    background-color: #1e7c6b;
    border-top-left-radius: 1.5rem 50%;
    border-top-right-radius: 1.5rem 50%;
    border-bottom-left-radius: 1.5rem 50%;
    border-bottom-right-radius: 1.5rem 50%;
    margin: -1px; /* To avoid the container border */
}
</style>